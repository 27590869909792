import React, {useEffect, useState} from 'react';
import {DeliverySection} from '@wix/delivery-section-checkout/widget';
import {DisplayState} from '@wix/delivery-section-checkout/types';
import {NextStepButton} from '../../../../StepsManager/Components/NextStepButton/NextStepButton';
import {useLocaleKeys} from '../../../../../../../locale-keys/LocaleKeys';
import {StatesButtonStates} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../../../Widget/ControllerContext';
import {classes} from './DeliverySectionWrapper.st.css';
import {StepState} from '../../../../../../../types/checkoutApp.types';

export enum DeliverySectionWrapperDataHook {
  root = 'DeliverySectionWrapperDataHook.root',
  button = 'DeliverySectionWrapperDataHook.button',
}

interface DeliverySectionWrapperParams {
  stepState: StepState;
}

const stepStateToDisplayState: Record<StepState, DisplayState> = {
  [StepState.EMPTY]: DisplayState.HIDDEN,
  [StepState.COLLAPSED]: DisplayState.READ_ONLY,
  [StepState.OPEN]: DisplayState.EDITABLE,
};

export const DeliverySectionWrapper: React.FC<DeliverySectionWrapperParams> = ({
  stepState,
}: DeliverySectionWrapperParams) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {onDeliveryMethodContinue},
    deliveryStore: {hasSelectedOption},
  } = useControllerProps();

  const [isStepTransition, setIsStepTransition] = useState(false);

  useEffect(() => {
    setIsStepTransition(false);
  }, [stepState]);

  const onStepTransition = () => {
    setIsStepTransition(true);
    onDeliveryMethodContinue();
  };

  return (
    <div data-hook={DeliverySectionWrapperDataHook.root} className={classes.root}>
      <DeliverySection displayState={stepStateToDisplayState[stepState]} />
      {stepState === StepState.OPEN && (
        <div className={classes.nextStepButton}>
          <NextStepButton
            onClick={() => void onStepTransition()}
            text={localeKeys.checkout.continue_button_label()}
            dataHook={DeliverySectionWrapperDataHook.button}
            disabled={!hasSelectedOption || isStepTransition}
            buttonState={
              isStepTransition ? /*istanbul ignore next*/ StatesButtonStates.IN_PROGRESS : StatesButtonStates.IDLE
            }
          />
        </div>
      )}
    </div>
  );
};
